<template>
    <v-form ref="form_4">
        <v-row >
            <v-col lg="3" xs="6"  class="px-2">
                <v-text-field
                    v-if="!somenteImportacao"
                    v-model="buscaProduto"
                    append-icon="mdi-magnify"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    dense
                    outlined
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template></v-text-field
                >
            </v-col>
            <v-col lg="2" xs="6" class="px-2">
                <v-text-field
                    v-if="!somenteImportacao"
                    label="VB"
                    v-model="vb"
                    class="input-cinza py-0"
                    outlined
                    dense
                    v-mask="'####'"
                    single-line
                ></v-text-field>
                
            </v-col>
            <v-col lg="7" xs="6" class="px-2">
                <v-btn class="new-button" color="primary" @click="carregarVB()" v-if="!somenteImportacao">
                    <v-icon left>mdi-arrow-down</v-icon> Carregar VB
                </v-btn>
                
                
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="recarregarProduto()"
                    v-if="!somenteImportacao"
                >
                    <v-icon left>mdi-autorenew</v-icon> Recarregar Produtos
                </v-btn>
                <v-btn
                    class="ml-4 new-button"
                    color="primary"
                    @click="abrirImportacao()" 
                >
                    <v-icon left>mdi-upload</v-icon> Importar Base de Produtos
                </v-btn>
                
            </v-col>
        </v-row>
        <v-row>    
            <v-col cols="3" class="py-4 ">
                <v-select
                    label="Categoria"
                    outlined
                    dense
                    v-model="value.id_categoria_produto"
                    :items="lista.categoria_produto"
                    :rules="regra.categoria"
                    class="input-cinza"
                    :menu-props="menuProps"
                    required
                ></v-select>

            </v-col>
            <v-col cols="9"   class="px-2" align="end">
                
            </v-col>
        </v-row>
        
            <v-data-table
                v-if="!somenteImportacao"
                v-model="produtoSelecionado"
                :headers="listaHeaderProduto"
                :items="produto" 
                :search="buscaProduto"
                item-key="id_produto"
                class="elevation-0 tabela"
                :items-per-page="5"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100, -1],
                }"
                :options.sync="pagination"
                show-select
            >
                <template v-slot:[`item.vb`]="{ item }">
                    <v-text-field
                        :ripple="false"
                        dense
                        outlined
                        v-mask="'####'"
                        class="input-cinza"
                        v-model="item.vb"
                        hide-details
                    ></v-text-field>
                </template>
            </v-data-table>
        
        <v-row no-gutters class="mb-3 mt-1">
            
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    v-if="!somenteImportacao"
                    class="ml-2 mb-1 new-button"
                    color="error"
                    @click="limpaProdutos(true)"
                >
                    <v-icon left>mdi-trash-can</v-icon> Limpar Produtos
                </v-btn>

                <v-btn
                    v-if="!somenteImportacao"
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="carregarProduto()"
                >
                    <v-icon left>mdi-arrow-up</v-icon> Carregar Produtos Salvos
                </v-btn>
                <v-btn
                    v-if="!somenteImportacao"
                    class="ml-2 mb-1 new-button"
                    color="primary"
                    @click="salvarProduto()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Salvar Produtos
                    Selecionados
                </v-btn>
            </v-col>
        </v-row>

        <!-- <v-data-table
            :headers="listaHeaderProdutoSalvo"
            :items="somaProdutoSalvo"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
        </v-data-table> -->
        <v-card elevation="0" class="pa-5 ">
            <v-layout column style="height: 400px">
            <v-flex  style="overflow: auto">        
            <v-data-table
                :headers="listaHeaderProdutoSalvo"
                :items="produtoSalvo"
                item-key="nome"
                class="elevation-0 tabela mt-4"
                hide-default-footer
                disable-sort
                :items-per-page="-1"
                :footer-props="{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100, -1],
                }"
                :options.sync="paginationSalvo"
            >
            <template v-slot:[`item.vb`]="{ item }">
                    <v-text-field
                        :ripple="false"
                        dense
                        outlined
                        class="input-cinza"
                        v-mask="'####'"
                        v-model="item.vb"
                        hide-details
                    ></v-text-field>
                </template>
            </v-data-table>
            </v-flex>
            </v-layout>       
        </v-card>
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 6"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>

        <v-overlay :value="carregando">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <importar-produtos
            ref="importarProdutos"
            :assinatura="value.assinatura"
            @importar="importar"
            @exportarModelo="exportarModelo"
        ></importar-produtos>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
    </v-form>
</template>

<script>
import importarProdutos from "./importarProdutos.vue";
import _ from  'lodash'
const originalHeaderProdutosSalvo = [
    {
        text: "Produtos Salvos",
        value: "nome",
        align: "center",
        class: "th-separador",
    },
    { text: "Quantidade de VB", value: "vb", align: "center" },
]
export default {
    name: 'acordoProdutos',
    inject: ["listaCampos"],
    components: {
        importarProdutos,
    },
    props: ["value"],
    async created() {
        var lista = await this.listaCampos;
        this.lista.categoria_produto = lista.categoria_produto;
    },
    data() {
        return {
            acordo: {},
            produto: [],
            produtoServidor: [],
            produtoSelecionado: [],
            produtoSalvo: [],
            somaProdutoSalvo: [],
            dialogoCamposFaltando: false,
            buscaProduto: "",
            vb: "",
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            listaHeaderProduto: [
                {
                    text: "Código",
                    value: "codigo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Descrição",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Ativo/Inativo",
                    value: "ativo_inativo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Curva do produto",
                    value: "curva",
                    align: "center",
                    class: "th-separador",
                },
                { text: "VB", value: "vb", align: "center", width: "120px" },
            ],
            listaHeaderProdutoSalvo: [
                {
                    text: "Produtos Salvos",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                { text: "Quantidade de VB", value: "vb", align: "center" },
            ],
            lista: {},            
            carregando: false,
            pagination: {},
            paginationSalvo: {},
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            semAcesso: false,
            regra: {
                categoria: [(v) => !!v || "O campo categoria é obrigatório"],
            }
        };
    },
    computed: {
        camposFaltando() {
            const campos = [];
            if (!this.value.id_fornecedor) {
                campos.push("Fornecedor");
            }
            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
        somenteImportacao(){
            return ['MD', 'RG'].includes(this.value.metodo_criacao)
        },
        acordo_regiao() {
            return this.value.metodo_criacao == 'RG'
        },
        acordo_movel_dermo() {
            return this.value.metodo_criacao == 'MD'
        },
    },
    methods: {
        setAcordo(acordo) {
            //this.acordo = { ...acordo };
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        ajusteUsoRegioes () {
            // console.log(this.value.usar_regioes)
            // delete  this.listaHeaderProdutoSalvo.vb
            this.listaHeaderProdutoSalvo[1].align = this.somenteImportacao?  "  d-none" : "center"
        },
        async buscaProdutos(produto) {
            this.listaHeaderProdutoSalvo = _.clone(originalHeaderProdutosSalvo)
            console.log(this.value.regioesSelecionadas)
            this.ajusteUsoRegioes()
            if (this.value.metodo_criacao == 'RG') {
                for (let c = 0;c < this.value.regioesSelecionadas.length;c++) {
                    this.listaHeaderProdutoSalvo.push({ text: this.value.regioesSelecionadas[c], value: this.value.regioesSelecionadas[c], align: "center" },)
                }
            }
            if (this.value.metodo_criacao == 'MD') {
                for (const movelDermo of this.value.moveisDermo) {
                    this.listaHeaderProdutoSalvo.push({ text: movelDermo.text, value: `${movelDermo.id_espaco}_vb`, align: "center" },)
                }
            }
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
                return;
            }
            if (!this.value.fnEditar && this.produto.length > 0) {
                this.dialogoCamposFaltando = false;
                return;
            }
            if (this.produtoServidor.length > 0) {
                if (this.value.metodo_criacao == 'RG') {
                    this.produtoSelecionado = this.produtoSelecionado.map(item => {
                        return {...item,...this.value.regioesSelecionadas,...item.regiao}
                    })
                }
                this.salvarProduto(false);
                return;
            }
            // console.log("buscaProdutos");
            this.produtoSelecionado = [];

            this.dialogoCamposFaltando = false;
            this.carregando = true;
            this.carregando = true;

            var data = {};

            data.id_fornecedor = this.value.id_fornecedor;

           if(this.value.metodo_criacao == 'MD') {
                data.metodo_criacao = 'MD'
                data.moveisDermo = this.value.moveisDermo
                data.idAcordosLote = this.value.idAcordosLote;

            }

            if (this.value.fnEditar || this.value.fnReplicar) {
                data.id_acordo = this.value.idAcordos[0];
                data.idAcordosLote = this.value.idAcordosLote;
            }

            var response = await this.$http().post("/acordo/produtos", {
                data: data,
            });

            // this.produto = response.data;

            // this.produtoServidor = response.data;

            // this.pagination.page = 1;

            // if (this.value.fnEditar || this.value.fnReplicar) {
            //     for (const p of this.produto) {
            //         if (p.vb != null) {
            //             this.produtoSelecionado.push(p);
            //         }
            //     }
            //     this.salvarProduto(false);
            // }

            // this.carregando = false;
            produto = response.data

            this.produto = response.data;
            this.produtoServidor = response.data;
            if (this.value.fnEditar || this.value.fnReplicar) {
                if (this.value.metodo_criacao == 'RG') { 
                    let produtoComRegioes = JSON.parse(this.value.produto)
                    console.log(response.data,produtoComRegioes)


                //     response = await this.$http().post("/acordo/produtosPorRegioes", {
                //         data: data,
                //     });

                    var produtos = response.data.map(item => {
                        delete item.vb
                        for (var confronto  of produtoComRegioes) {
                            if (confronto.id_produto == item.id_produto) {
                                item = {...item, regiao: confronto.regiao}
                            }
                        }
                        return item
                    }) 
                    this.produto = produtos
                    this.produtoServidor = produtos;
                } else if (this.value.metodo_criacao == 'MD') {

                    for (const produto of this.produto) {
                        let selecionado = false;
                        for (const movelDermo of this.value.moveisDermo) {

                            const coluna = movelDermo.id_espaco
                            if (produto[`${coluna}_vb`] === 0 || produto[`${coluna}_vb`] > 0) {
                                selecionado = true
                              
                            }
                        }
                        if (selecionado) {
                            this.produtoSelecionado.push(produto);
                            
                        }
                    }
                    /// Carrega produtos MD
                } 
            }    
            this.pagination.page = 1;
                

            if (this.value.fnEditar || this.value.fnReplicar) {
                for (const p of this.produto) {
                    if ((p.vb != null) || (p.regiao)) {
                        this.produtoSelecionado.push(p);
                    }
                }
                this.salvarProduto(false);
            }
            this.carregando = false;
        },
        carregarVB() {
            for (const d of this.produto) {
                d.vb = this.vb;
            }
        },
        salvarProduto(alert = true) {
            this.produtoSalvo = [];

            var vb = 0;
            var fnVB = 0;

            for (const d of this.produtoSelecionado) {
                this.produtoSalvo.push({ ...d });

                // if (d.vb * 1 > 0) {
                //     vb += d.vb * 1;
                // } else {
                //     fnVB = 1;
                // }
                if (this.value.metodo_criacao == 'RG') {
                    let _vb = _.values(d.regiao).reduce((a,c) => a + c)
                    vb += _vb
                    if (_vb == 0) fnVB = 1;                        
                } else {
                    if (d.vb * 1 > 0) {
                        vb += d.vb * 1;
                    } else {
                        fnVB = 1;
                    }
                }
            }

            if (fnVB && alert && this.value.metodo_criacao != 'MD') {
                this.alerta = true;
                this.alertaColor = "info";
                this.alertaTimeout = 3000;
                this.alertaTexto = "Existem produtos com VB igual a zero.";
            }

            var soma = [
                {
                    produto: this.produtoSalvo.length,
                    vb: vb,
                },
            ];

            this.somaProdutoSalvo = soma;
        },
        carregarProduto() {
            this.produto = [];
            this.produtoSelecionado = [];

            for (const p of this.produtoSalvo) {
                this.produto.push(p);
    
                if ( +p.vb > 0) {
                    this.produtoSelecionado.push(p);
                }
            }

            this.pagination.page = 1;
        },
        recarregarProduto() {
            this.produto = [];

            for (const d of this.produtoServidor) {
                this.produto.push({ ...d });
            }

            this.pagination.page = 1;
        },
        limpaProdutos(fnServidor = false) {
            this.produto = [];
            this.produtoServidor = [];
            this.produtoSelecionado = [];
            this.produtoSalvo = [];
            this.somaProdutoSalvo = [];
            this.vb = "";

            if (fnServidor) {
                this.buscaProdutos();
            }
        },
        async abrirImportacao() {
            console.log(this.value.metodo_criacao,512)
            this.$refs.importarProdutos.abrir(this.produtoServidor,this.value.metodo_criacao);
        },
        async importar() {
            var dadosTemp = this.$refs.importarProdutos.dadosTemp;

            this.produto = [];
            this.produtoSelecionado = [];

            if (this.value.metodo_criacao == 'RG') {
                console.log('paasou aqui regiao 521')
                var keys = []
                for (const p of dadosTemp) {
                    if (p.target && keys.length == 0) {
                        delete p.target
                        keys = _.keys(p)
                    }    
                }
    
                console.log(dadosTemp)
    
                for (const p of dadosTemp) {
                    if (this.value.metodo_criacao == 'RG') {
                        p['regiao'] = {}
                        // delete p.vb
                    } 
                    this.produto.push(p);
    
                    // if (typeof p.vb == "number") {
                    //     this.produtoSelecionado.push(p);
                    // }
                    var fazerAdicao = false
                    for (let c = 6;c < keys.length;c++) {
                        
                        if (typeof p[keys[c]] == "number") {
                            if (this.value.metodo_criacao == 'RG') p.regiao[keys[c]] = p[keys[c]]
                            fazerAdicao = true
                            // console.log(this.value.usar_regioes,p,keys,c)
                        } 
                    }
                    if (fazerAdicao)
                        this.produtoSelecionado.push(p);
                }
                this.listaHeaderProdutoSalvo = _.clone(originalHeaderProdutosSalvo)
                // console.log(this.listaHeaderProdutoSalvo)
                if (this.value.metodo_criacao == 'RG') { 
                    for (let c = 7;c < keys.length;c++) {
                        if ((keys[c] !== 'regiao') && (keys[c] !== 'vb'))
                            this.listaHeaderProdutoSalvo.push({ text: keys[c], value: keys[c], align: "center" },)
                    }
                    
                }
                this.ajusteUsoRegioes()
            } else if(this.value.metodo_criacao == 'MD') {
                for (const produto of dadosTemp) {
                        produto['md'] = {}
                        this.produto.push(produto);

                        /// confere as colunas selecionadas
                        let selecionado = false
                        for (const movelDermo of this.value.moveisDermo) {
                            const coluna = `${movelDermo.id_espaco}_vb`
                            // console.log('testou...',produto[coluna],(produto[coluna] == 0 || (produto[coluna] !== null && produto[coluna] != "")))
                            if ((produto[coluna] === 0 || (produto[coluna] !== null && produto[coluna] != ""))) {
                                // produto[coluna] 
                                selecionado = true
                                // console.log(produto[coluna])
                            }
                        }
                        if (selecionado) {
                            this.produtoSelecionado.push(produto);
                        }
                }
                this.listaHeaderProdutoSalvo = _.clone(originalHeaderProdutosSalvo)

                    for (const movelDermo of this.value.moveisDermo) {
                        this.listaHeaderProdutoSalvo.push({ text: movelDermo.text, value: `${movelDermo.id_espaco}_vb`, align: "center" },)
                    }
                    // console.log(this.listaHeaderProdutoSalvo)
                    this.ajusteUsoRegioes()
            }else {
                console.log('padrao',dadosTemp)
                for (const p of dadosTemp) {
                    this.produto.push(p);
                    if (p.vb === 0 || p.vb > 0) {
                        console.log(p.vb)
                    }
                    if (p.vb === 0 || p.vb > 0) {
                        this.produtoSelecionado.push(p);
                    }
                 
                }
            }

            this.salvarProduto();
        },
        async exportarModelo() {
            this.$refs.importarProdutos.carregando = true;

            var data = {};

            data.id_fornecedor = this.value.id_fornecedor;
            data.assinatura = this.value.assinatura;

            if (this.value.fnEditar || this.value.fnReplicar) {
                data.id_acordo = this.value.idAcordos[0];
            }

            if (this.value.metodo_criacao == 'RG') {
                data.metodo_criacao = 'RG'
                data.regioesSelecionadas = this.value.regioesSelecionadas
            }  
            if(this.value.metodo_criacao == 'MD') {
                data.metodo_criacao = 'MD'
                data.moveisDermo = this.value.moveisDermo
                data.idAcordosLote = this.value.idAcordosLote;

            }

            var response = await this.$http().post("/acordo/modeloProdutos", {
                data: data,
            });
            if(response.data?.url){

                window.location = this.$http("baseURL") + response.data.url;
            }

            this.$refs.importarProdutos.carregando = false;
        },
        validate() {
            if (this.value.metodo_criacao == 'RG') {
                console.log('conferencia por regioes')    
                const confereRegioes = this.value.regioesSelecionadas.map(item => {
                var confere = false
                for (var itemSalvo of this.produtoSalvo) {
                    if (itemSalvo[item] != "") {
                    confere = true
                    }
                }
                return {
                    regiao:item,
                    confere
                }
                })

                var conferencia = true
                for (var item of confereRegioes) {
                    if (!item.confere) {
                        conferencia = false
                    }
                }
                return conferencia

                
            }
            console.log("quantidade de produtos", this.produtoSalvo);
            if (this.produtoSalvo.length > 0) {
                return this.$refs.form_4.validate()
            }
            return false;
            // else if (this.somaProdutoSalvo[0].produto == 0) {
            //     console.log("erro2");
            //     return true;
            // }
        },
    },
};
</script>

<style >

.involucroProdutos {
    height: 450px;
    display: flex;
    /* margin-top: 20px; */
    /* flex-grow: 1; */
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll; 
}

.involucroProdutos .flex-table {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    height: 420px;
    width: 100%;
}

.involucroProdutos .flex-table>div {
    width: 100%;
}

.involucroProdutos header {
    top: 0px;
    background-color: #ffff;
    z-index: 4;
}
</style>